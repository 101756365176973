<template>
    <div class="sendallTeacher">
        <el-card>
        <div class="selectSchool">
            <div class="tip">填写公告内容 : </div>
                <div class="tip">
                    <el-input
                    type="textarea"
                    :rows="10"
                    placeholder="请输入通知内容"
                    v-model="noticeContent">
                    </el-input>
                </div>
            <div @click="sendNotice"> 
                <el-button class="themeButton">发送公告</el-button>
            </div>
        </div>
       
        </el-card>
    </div>
</template>
<style lang="scss" >

     @import "@/assets/css/manager/other/sendallTeacher.scss";
</style>
<script>
    import sendallTeacher from "@/assets/js/manager/other/sendallTeacher.js";
    export default {
        ...sendallTeacher
    };
</script>